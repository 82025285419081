﻿TABLE.pricetable {
    margin-left: auto;
    margin-right: auto;

     .pricetitle TD, .pricetitle {
        background-color: #6495ED;
        border: solid 2px white;
        color: white;
        font-weight: bold;
        padding: 8px 12px;
    }

    .pricedata TD, TD.pricedata {
        background-color: #eeeeee;
        border: solid 2px white;
        padding: 8px 12px;
    }
}

/* Register Stuff */
.form-control-checkbox {
    @extend .mt-2;
    display:inline-block;

    input[type="checkbox"] + label {
        font-weight: normal;
        margin-left: 6px;
        display: inline;
    }
}

fieldset{
    @extend .mb-3;
}

.regsum {
    width: 100%;
    border-collapse: collapse;

    .regsumdesc {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 8px;
        width: 75%;
    }

    .regsumprice {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 8px;
        text-align: right;
        width: 25%;
    }

    .regsumdescfoot {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 8px;
        border-top: solid 1px black;
        font-weight: bold;
    }

    .regsumpricefoot {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 8px;
        text-align: right;
        border-top: solid 1px black;
        font-weight: bold;
    }
}
