﻿

.navbar {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);

    .navbar-logo {
        width: 292.5px;
        height: 60px;
    }

    .nav-item-register {
        color:darkred;
    }
}

.headerarea {
    background-color: #efefef;

    h1 {
        @extend .py-4;
        font-weight:300;
        color: #343466;
    }
}

main{
    @extend .py-3;
}


.navbar-custom {
    background-color: #666633;
}

footer {
    background: #343466;
    @extend .py-1;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.75rem;

    a {
        color: #fff;
    }
}
