﻿.member {
    .modulelist {
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        margin-bottom: 10px;

        .module-status {
            text-align: center;
        }

        .module-action {
            text-align: center;
        }

        td, th {
            padding: 8px 12px;
        }

        th {
            background-color: #6495ED;
            border: solid 2px white;
            color: white;
            font-weight: bold;
        }

        td {
            background-color: #eeeeee;
            border: solid 2px white;
        }
    }


    .lesson-top {
        @extend .p-2;
        background-color: #808080;
        color: #fff;

        h5 {
            @extend .mb-0;
        }
    }

    .lesson-time {
        border-bottom: solid 4px #a6a6a6;
        background-color: #eaeaea;
        @extend .p-2;

        .chapter-seconds {
            @extend .text-monospace;
            @extend .d-inline;
            @extend .p-1;
            color: #fff;
            font-weight: bold;
            background-color: #929393;
            text-align: center;
        }
    }

    .lesson-area {
        @extend .col-sm-10;
        @extend .offset-sm-1;
        @extend .col-lg-8;
        @extend .offset-lg-2;
        text-align: left;

        .lesson-title {
            font-weight: bold;
            font-size: 18px;
        }

        .lesson-text {
        }
    }

    .lesson-bottom {
        @extend .p-2;
        background-color: #eaeaea;

        .time-seconds {
            @extend .text-monospace;
            @extend .d-inline-block;
            @extend .p-1;
            color: #fff;
            font-weight: bold;
            background-color: #929393;
            text-align: center;
        }
    }
    /* Outline page*/
    .OutlineBlock {
        font-weight: bold;

        .OutlineChapter {
            margin-left: 8px;
        }

        .OutlineLesson {
            margin-left: 24px;
        }

        .OutlineLesson A {
            color: Black;
        }

        .OutlineMedia {
            margin-left: 64px;
            font-style: italic;
            font-size: 14px;
        }

        .OutlineLessonNumber {
            color: #ff9900;
            width: 30px;
            float: left;
        }
    }
    /* quiz related */
    .exam-questionblock {
        @extend .mb-3;

        .exam-answers {
            @extend .ml-4;
        }

        .exam-question {
            font-weight: bold;
        }

        .exam-question-number {
            width: 20px;
            display: inline-block;
        }
    }

    .field-validation-error {
        border: 2px solid #ebccd1;
        border-radius: 6px;
        background-color: #f2dede;
        padding: 4px;
    }

    .validation-message {
        display: none;
        font-weight: bold;
        color: darkred;
    }

    .field-validation-error .validation-message {
        display: block;
    }

    .field-validation-valid {
    }

    .CourseSummary {
        width: 100%;
        border-collapse: collapse;
        background-color: #dedede;
        font-family: Arial, Helvetica, sans-serif;

        .ChapterNoHeader {
            border-right: solid 10px #9e9e9e;
            background-color: #9e9e9e;
            font-size: 13px;
            font-weight: bold;
            text-align: left;
            padding: 4px 0px 4px 1px;
            width: 56px;
        }

        .TitleHeader {
            border-right: solid 10px #9e9e9e;
            border-left: solid 10px #9e9e9e;
            background-color: #9e9e9e;
            font-size: 13px;
            font-weight: bold;
            text-align: left;
            padding-top: 4px;
            padding-bottom: 4px;
        }

        .TimeHeader {
            border-left: solid 10px #9e9e9e;
            background-color: #9e9e9e;
            font-size: 13px;
            font-weight: bold;
            text-align: left;
            padding-top: 4px;
            padding-bottom: 4px;
            width: 118px;
        }

        .ChapterNoFinished {
            background-image: url(../../images/check_yes2.gif);
            background-repeat: no-repeat;
            background-position: center left;
            width: 28px;
            height: 28px;
            border-right: solid 10px #9e9e9e;
            border-bottom: solid 10px #9e9e9e;
            background-color: white;
            text-align: center;
            padding-left: 28px;
            font-weight: bold;
            color: #ff9900;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
        }

        .ChapterNoUnFinished {
            background-image: url(../../images/check_no.gif);
            background-repeat: no-repeat;
            background-position: center left;
            width: 28px;
            height: 28px;
            border-right: solid 10px #9e9e9e;
            border-bottom: solid 10px #9e9e9e;
            background-color: white;
            text-align: center;
            padding-left: 28px;
            font-weight: bold;
            color: #ff9900;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
        }

        .ChapterTitle {
            height: 28px;
            border-right: solid 10px #9e9e9e;
            border-left: solid 10px #9e9e9e;
            border-bottom: solid 10px #9e9e9e;
            text-align: left;
            padding-left: 8px;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
        }

        .ChapterTitle A:link, .ChapterTitle A:visited, .ChapterTitle A:active {
            color: black;
        }

        .ChapterTitle A:hover {
            color: red;
            text-decoration: underline;
        }

        .TimeFinished {
            height: 28px;
            border-left: solid 10px #9e9e9e;
            border-bottom: solid 10px #9e9e9e;
            background-image: url(../../images/button_completed.gif);
            background-repeat: no-repeat;
            background-position: center center;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
        }

        .TimeRequired {
            height: 28px;
            border-left: solid 10px #9e9e9e;
            border-bottom: solid 10px #9e9e9e;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
        }

        .TimeRequired TABLE {
            width: 100%;
            height: 28px;
        }

        .MinuteCount {
            background-color: white;
            height: 28px;
            width: 28px;
            text-align: center;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
        }

        .MinuteLabel {
            text-align: center;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
        }
    }
}
